import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import {AnySchema, string} from 'yup';
import * as Yup from 'yup';
import { useLocation } from '@reach/router';
import { useMutation } from '@tanstack/react-query';
import {navigate} from "gatsby";

import {
    layout,
    navigationContainer,
    navigation,
    navigationLink,
    sectionList,
    sectionClass,
    surveyContainer,
    formSection,
    lastSection,
    active,
    submitButton,
    errorMessage,
} from './survey.module.scss';
import { ISection } from '../../models/section.model';
import { ISurvey, ISurveyGroup } from '../../models/survey.model';
import { IFileBase64 } from '../../models/file-base-64.model';
import { IErrorResponse } from '../../models/api-models.model';
import useTranslations from '../../hooks/use-translations';
import { postSurvey } from '../../api-clients/survey';
import { toast } from '../hoc/toast';
import {useUser} from "../../hooks/use-user";

import SurveyGroup from '../organisms/survey-group';
import FieldGenerator from '../molecules/field-generator';
import Button from '../atoms/button';

export interface ISurveySection extends ISection {
    items: {
        form: ISurvey;
    };
}

interface ISurveyProps {
    className?: string;
    section: ISurveySection;
}

const Survey: React.FC<ISurveyProps> = ({ className, section }) => {
    const [activeGroup, setActiveGroup] = useState(1);
    const { sectionId, css, style, items } = section;
    const { form } = items;
    const id = typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get('order') : null;
    const {isLoggedIn} = useUser();

    const formRef = useRef<FormikProps<ReturnType<typeof getSurveyInitialValues>>>(null);

    const t = useTranslations('Survey');

    const onSectionScroll = (id: number) => {
        setActiveGroup(id);
    };

    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const orderUUID = urlParams.get('order');

    const mutation = useMutation(postSurvey, {
        onError: handleMutationError,
        onSuccess: handleSuccess,
    });

    function handleSuccess() {
        toast(`${form.summary} <a href='/'>${t.redirect}</a>`, { type: 'success' });
        formRef.current?.resetForm();
    }

    function handleMutationError(data: { response: { data: IErrorResponse } }) {
        const errors = data.response.data.messages;
        errors.forEach((error) => {
            toast(error.content, { type: 'error', autoClose: 5000 });
        });
    }

    const handleSubmit: FormikConfig<ReturnType<typeof getSurveyInitialValues>>['onSubmit'] = (
        values
    ) => {
        if (!orderUUID) {
            toast(t.uuid.error, { type: 'error', autoClose: 5000 });
            return;
        }
        const elements = Object.entries(values);
        const data = {
            orderId: orderUUID,
            formId: form.formId,
            elements: elements.map((element) => {
                if (typeof element[1] !== 'string' && 'content' in element[1]) {
                    return {
                        elementId: element[0],
                        option: [element[1].content?.toString() || ''] || '',
                    };
                }
                return {
                    elementId: element[0],
                    option: element[1],
                };
            }),
        };
        mutation.mutate(data);
    };

    const handleSubmitClick = () => {
        formRef.current?.submitForm().then(() => {

            let firstError: any;
            const errors = formRef.current ? Object.keys(formRef.current.errors) : [];

            if (errors.length > 0) {

                errors.forEach(item => {
                    const element = document.querySelector(`form .field-${item.toString()}`);

                    if (element) {
                        const offsetTop = element.getBoundingClientRect().top;

                        if (!firstError) {
                            firstError = element;
                        }
                        else {
                            if (offsetTop < firstError.getBoundingClientRect().top) {
                                firstError = element;
                            }
                        }
                    }
                })

                if (firstError && typeof window !== 'undefined') {
                    firstError.scrollIntoView();
                }
            }
        });
    }

    useEffect(() => {
        if (!id || !isLoggedIn) {
            navigate('/');
        }

    }, [isLoggedIn,id]);

    return (
        <section
            id={`section-${sectionId ? sectionId : 'u'}`}
            className={`${className}, ${layout}`}
            style={style}
        >
            {css && <style>{css}</style>}
            <aside className={navigationContainer}>
                <nav className={navigation}>
                    <ul className={sectionList}>
                        {form.groups.map((group: ISurveyGroup, index: number) => {
                            if (index !== form.groups.length - 1) {
                                return (
                                    <li
                                        className={`${sectionClass} ${
                                            activeGroup === group.id ? active : ''
                                        }`}
                                        key={`survey-navigation-${group.id}`}
                                    >
                                        <a className={navigationLink} href={`#${group.name}`}>
                                            {group.name}
                                        </a>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                </nav>
            </aside>
            <main className={surveyContainer}>
                <Formik
                    initialValues={getSurveyInitialValues(form)}
                    validationSchema={getValidationSchema(form)}
                    onSubmit={handleSubmit}
                    innerRef={formRef}
                >
                    {({ errors, dirty }) => (
                        <Form>
                            {form.groups.map((group: ISurveyGroup, index: number) => {
                                return (
                                    <SurveyGroup
                                        id={group.id}
                                        name={group.name}
                                        label={group.name}
                                        isActive={activeGroup === group.id}
                                        className={`${formSection} ${
                                            form.groups.length === group.id ? lastSection : ''
                                        }`}
                                        isLastGroup={form.groups.length === group.id}
                                        handleSectionScroll={onSectionScroll}
                                        key={`survey-group-${group.id}`}
                                    >
                                        {group.elements.map((element) => (
                                            <FieldGenerator
                                                field={{
                                                    ...element,
                                                    name: element.elementId.toString(),
                                                }}
                                                key={`survey-field-${element.elementId}`}
                                            />
                                        ))}
                                        {index === form.groups.length - 1 && (
                                            <>
                                                <Button
                                                    type={'button'}
                                                    as={'button'}
                                                    onClick={() => {handleSubmitClick()}}
                                                    className={submitButton}
                                                    isLoading={mutation.isLoading}
                                                >
                                                    {t.button}
                                                </Button>
                                                {dirty && Object.entries(errors).length > 0 && (
                                                    <p className={errorMessage}>
                                                        Nie wszystkie pola zostały wypełnione.
                                                        Prosimy wypełnić wszystkie wymagane pola
                                                        przed przesłaniem formularza.
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </SurveyGroup>
                                );
                            })}
                        </Form>
                    )}
                </Formik>
            </main>
        </section>
    );
};

const getSurveyInitialValues = (form: ISurvey) => {
    const initialValues: {
        [key: string]: string | string[] | IFileBase64;
    } = {};

    form.groups.forEach((group: ISurveyGroup) => {
        group.elements.forEach((element) => {
            initialValues[`${element.elementId}`] = element.initialValue;
        });
    });

    return initialValues;
};

function getValidationSchema(form: ISurvey) {
    const validationSchema: { [key: string]: AnySchema } = {};

    form.groups.forEach((group: ISurveyGroup) => {
        group.elements.forEach((element) => {
            if (element.type === 'checkbox-button') {
                validationSchema[element.elementId] = Yup.array().of(Yup.string());
            } else if (element.type === 'flag') {
                validationSchema[element.elementId] = Yup.array()
                    .of(Yup.string())
                    .min(1, 'Pole wymagane')
                    .required(`Pole wymagane`);
            } else if (element.type === 'file') {
                validationSchema[element.elementId] = Yup.object()
                    .shape({ content: string(), mimeType: string(), name: string() })
                    .test(`${element.elementId}`, 'Pole wymagane', (value) => {
                        return !!value.name || !!value.content || !!value.mimeType
                    })
            } else {
                validationSchema[element.elementId] = Yup.string().required(`Pole wymagane`);
            }
        });
    });

    return Yup.object().shape(validationSchema);
}

export default Survey;
