import React, { useRef } from 'react';
import useScroll from '../../hooks/use-scroll';

import { activeSection, title } from './survey-group.module.scss';

interface IWizardFormSectionProps {
    children: React.ReactNode;
    id: number;
    name: string;
    label: string;
    isActive: boolean;
    className: string;
    handleSectionScroll: (id: number) => void;
    isLastGroup: boolean;
}

const SurveyGroup: React.FC<IWizardFormSectionProps> = ({
    id,
    name,
    label,
    isActive,
    className,
    handleSectionScroll,
    children,
    isLastGroup,
}) => {
    const sectionRef = useRef<HTMLDivElement>(null);

    const checkSectionActive = () => {
        if (sectionRef.current && window.scrollY + 200 > sectionRef.current.offsetTop) {
            handleSectionScroll(id);
        }
    };

    useScroll(checkSectionActive);

    return (
        <div ref={sectionRef} id={name} className={`${className}`}>
            {!isLastGroup && (
                <h2 className={`${title} ${isActive ? activeSection : ''}`}>{label}</h2>
            )}
            {children}
        </div>
    );
};

export default SurveyGroup;
