// extracted by mini-css-extract-plugin
export var active = "survey-module--active--ef776";
export var errorMessage = "survey-module--error-message--1ac5f";
export var formSection = "survey-module--form-section--7e532";
export var lastSection = "survey-module--last-section--5b2f0";
export var layout = "survey-module--layout--a96b7";
export var navigation = "survey-module--navigation--cd92b";
export var navigationContainer = "survey-module--navigation-container--67be8";
export var navigationLink = "survey-module--navigation-link--98d93";
export var sectionClass = "survey-module--section-class--59e54";
export var sectionList = "survey-module--section-list--80613";
export var submitButton = "survey-module--submit-button--34470";
export var surveyContainer = "survey-module--survey-container--d2680";